import moment from "moment";
import { ILoan } from "../../../../../types/loan";
import { ICashFlow, NetCashflowWaterfallDistribution } from "../../../../../types/monthly-proforma/net-cashflow-waterfall-distribution";
import { IProperty } from "../../../../../types/property";

const getHypotheticalReturns = (
  property: IProperty,
  loan: ILoan,
  distribution: NetCashflowWaterfallDistribution,
  exitMonth: number,
): ICashFlow => {
  const { monthlyProforma } = distribution!;

  let exitDispositionSummary = {...property.exitDispositionSummary};
  let goingInCapRate = (property.getGoingInNoi() / loan.acquisitionCosts.purchasePrice) * 100
  let startMonth = exitMonth;
  let noiTrailing12Months = 0;
  while (startMonth > exitMonth - 12) {
    if (typeof monthlyProforma[startMonth] !== 'undefined') {
      noiTrailing12Months += monthlyProforma[startMonth].netOperatingIncome;
    }
    startMonth -= 1;
  }

  exitDispositionSummary.date = moment(loan.loanAssumptions.date).add(exitMonth, 'M').endOf('M').toDate();
  exitDispositionSummary.noiTrailing12Months = noiTrailing12Months;
  exitDispositionSummary.grossSaleProceeds = noiTrailing12Months / (exitDispositionSummary.capRate / 100);
  exitDispositionSummary.capRateSpread = (exitDispositionSummary.capRate - goingInCapRate) * 100;
  exitDispositionSummary.saleClosingCosts = exitDispositionSummary.grossSaleProceeds * (exitDispositionSummary.saleClosingCostsPercentage/100);
  exitDispositionSummary.dispositionFee = exitDispositionSummary.grossSaleProceeds * (exitDispositionSummary.dispositionFeePercentage/100);
  if (exitMonth >= monthlyProforma[0].refinanceMonth) {
    exitDispositionSummary.loanRepaymentAtSale = monthlyProforma[exitMonth].financing.refinanceLoan.outstandingLoanBalance;
  } else {
    exitDispositionSummary.loanRepaymentAtSale = monthlyProforma[exitMonth].financing.originalAcquisitionLoan.outstandingLoanBalance
    
  }
  // @ts-ignore
  exitDispositionSummary.occupancy = 100 - property.annualAssumptions.filter(x => x.name === "vacancyLoss")[0][`year${property.investmentTimeline.holdPeriodYears}`];
  exitDispositionSummary.netSaleProceeds = exitDispositionSummary.grossSaleProceeds - exitDispositionSummary.dispositionFee - exitDispositionSummary.saleClosingCosts - exitDispositionSummary.loanRepaymentAtSale;

  property = {
    ...property,
    investmentTimeline: {
      ...property.investmentTimeline,
      exitMonth,
      exitDispositionDate: exitDispositionSummary.date,
    },
    exitDispositionSummary,
  }

  const { hypotheticalCashFlow } = new NetCashflowWaterfallDistribution(property, loan);

  return hypotheticalCashFlow;
}

export default getHypotheticalReturns;