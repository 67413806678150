import { ILoan } from "../../../../types/loan";
import {
  ICashFlow,
  NetCashflowWaterfallDistribution,
} from "../../../../types/monthly-proforma/net-cashflow-waterfall-distribution";
import { IProperty } from "../../../../types/property";
import calculateExitDisposition from "./hypothetical-investment-return-vs-hold/calculate-hypothetical-returns";
import { utils, writeFile } from "xlsx";

interface IExportDataRow {
  name?: string;
  values: any[];
}

interface IInvestmentSummaryTable {
  exportData: IExportDataRow[];
}

export interface IPropertySummary extends IInvestmentSummaryTable {
  assetType: string;
  purchasePrice: number;
  totalBasis: number;
  totalUnits: number;
  totalSqFt: number;
  goingInCapRate: number;
  goingInNoi: number;
  goingInOccupancy: number;
}

const getPropertySummary = (
  property: IProperty,
  loan: ILoan,
  cashFlow: NetCashflowWaterfallDistribution
): IPropertySummary => {
  const {
    loanAssumptions: { debtServiceReservesPandITotal, loanFeesPercentage },
    acquisitionCosts: {
      purchasePrice,
      acquisitionFee,
      transferTaxes,
      dueDilligence,
      legalFees,
      reserves,
      otherClosingCosts,
    },
    loanTotal,
  } = loan;

  const {
    totalSqFt,
    totalUnits,
    reservesTaxes,
    reservesInsurance,
    renovations: { totalBudget },
    type,
    currentOccupancy,
  } = property;

  const { operatingCashFlowShortfall } = cashFlow;

  let acquisitionCosts: { total: number }[] = [
    { total: purchasePrice },
    { total: acquisitionFee },
    { total: transferTaxes },
    { total: loanTotal * (loanFeesPercentage / 100) },
    { total: dueDilligence },
    { total: legalFees },
    { total: totalBudget },
    { total: reserves },
    { total: otherClosingCosts },
    { total: debtServiceReservesPandITotal },
    { total: reservesTaxes.yearOneMonthlyAmount * reservesTaxes.months },
    {
      total: reservesInsurance.yearOneMonthlyAmount * reservesInsurance.months,
    },
    { total: operatingCashFlowShortfall },
  ];

  const total = acquisitionCosts
    .map((x) => x.total)
    .reduce((prev, curr) => prev + curr, 0);

  return {
    assetType: type,
    purchasePrice: purchasePrice,
    totalBasis: total,
    totalUnits,
    totalSqFt,
    goingInCapRate: (property.getGoingInNoi() / purchasePrice) * 100,
    goingInNoi: property.getGoingInNoi(),
    goingInOccupancy: currentOccupancy,
    exportData: [
      { values: ["Property Summary", null] },
      { name: "Asset Type", values: [null, type] },
      { name: "Purchase Price", values: [null, purchasePrice] },
      { name: "Total Basis", values: [null, total] },
      { name: "Total Units", values: [null, totalUnits] },
      {
        name: "Total Square Footage",
        values: [null, totalSqFt.toLocaleString()],
      },
      {
        name: "Going-In Cap Rate",
        values: [null, (property.getGoingInNoi() / purchasePrice) * 100],
      },
      { name: "Going-In NOI", values: [null, property.getGoingInNoi()] },
      { name: "Going-In Occupancy", values: [null, currentOccupancy] },
      { values: ["Per Unit", "Per Sq. Ft."] },
      {
        name: "Acquisition Price",
        values: [purchasePrice / totalUnits, purchasePrice / totalSqFt],
      },
      { name: "Total Basis", values: [total / totalUnits, total / totalSqFt] },
    ],
  };
};

export interface IOwnershipStructure extends IInvestmentSummaryTable {
  totalEquity: number;
  financing: number;
  totalCapitalization: number;
  acquisitionDate: Date;
  refinancing: boolean;
  refinanceDate: Date;
  exitMonth: number;
  exitDate: Date;
  acquisitionLoanInterestRate: number;
  acquisitionLoanAmortization: number;
}

const getOwnershipStructure = (
  property: IProperty,
  loan: ILoan,
  cashFlow: NetCashflowWaterfallDistribution
): IOwnershipStructure => {
  const {
    loanAssumptions: { date: loanDate, interestRate, amortizationYears },
    refinancingPermanentLoanAssumptions: { refinancing, date: refinanceDate },
    loanTotal,
  } = loan;

  const {
    investmentTimeline: { exitMonth, exitDispositionDate },
  } = property;

  const {  totalEquity } = cashFlow;

  return {
    totalEquity: totalEquity,
    financing: loanTotal,
    totalCapitalization: totalEquity + loanTotal,
    acquisitionDate: loanDate,
    refinancing,
    refinanceDate,
    exitMonth,
    exitDate: exitDispositionDate!,
    acquisitionLoanInterestRate: interestRate,
    acquisitionLoanAmortization: amortizationYears,
    exportData: [
      { values: ["Ownership Structure", null] },
      { values: ["Total $", "% of Total"] },
      {
        name: "   Total Equity",
        values: [
          totalEquity,
          100 * (totalEquity / (loanTotal + totalEquity)),
        ],
      },
      {
        name: "   Financing",
        values: [
          loanTotal,
          100 * (loanTotal / (loanTotal + totalEquity)),
        ],
      },
      {
        name: "Total Capitalization",
        values: [totalEquity + loanTotal, 100],
      },
      {
        name: "Acquisition Date",
        values: [null, loanDate.toLocaleDateString()],
      },
      {
        name: "   Refinancing?",
        values: [null, refinancing ? "Yes" : "No"],
      },
      {
        name: "   if yes, refinance date",
        values: [null, refinancing ? refinanceDate.toLocaleDateString() : null],
      },
      {
        name: "Exit Month (Projected)",
        values: [null, exitMonth],
      },
      {
        name: "Exit Date (Projected)",
        values: [null, exitDispositionDate?.toLocaleDateString()],
      },
      {
        name: "Acquisition Loan - Interest Rate",
        values: [null, interestRate],
      },
      {
        name: "Acquisition Loan - Amortization	",
        values: [null, amortizationYears],
      },
    ],
  };
};

export interface IProjectLevelReturns extends IInvestmentSummaryTable {
  unleveredProjectIRR: number;
  unleveredProjectEquityMultiple: string;
  leveredProjectIRR: number;
  leveredProjectEquityMultiple: string;
  exitCapRate: number;
  grossExitValue: number;
  grossExitValuePriceUnit: number;
}

const getProjectLevelReturns = (
  property: IProperty,
  cashFlow: NetCashflowWaterfallDistribution
): IProjectLevelReturns => {
  const {
    totalUnits,
    exitDispositionSummary: { capRate, grossSaleProceeds },
  } = property;

  const { unleveredCashFlow, leveredCashFlow} = cashFlow;

  return {
    unleveredProjectIRR: unleveredCashFlow.irr,
    unleveredProjectEquityMultiple:
      getCleanNumber(unleveredCashFlow.equityMultiple).toFixed(2) +
      "x",
    leveredProjectIRR: leveredCashFlow.irr,
    leveredProjectEquityMultiple:
      getCleanNumber(leveredCashFlow.equityMultiple).toFixed(2) + "x",
    exitCapRate: capRate,
    grossExitValue: grossSaleProceeds,
    grossExitValuePriceUnit: grossSaleProceeds / totalUnits,
    exportData: [
      { name: "Project Level Returns", values: [null] },
      {
        name: "Unlevered Project IRR	",
        values: [unleveredCashFlow.irr],
      },
      {
        name: "Unlevered Project Equity Multiple",
        values: [unleveredCashFlow.equityMultiple.toFixed(2) + "x"],
      },
      {
        name: "Levered Project IRR	",
        values: [leveredCashFlow.irr],
      },
      {
        name: "Levered Project Equity Multiple	",
        values: [leveredCashFlow.equityMultiple.toFixed(2) + "x"],
      },
      {
        name: "Exit Cap Rate	",
        values: [capRate],
      },
      {
        name: "Gross Exit Value",
        values: [grossSaleProceeds],
      },
      {
        name: "Gross Exit Value Price/Unit	",
        values: [grossSaleProceeds / totalUnits],
      },
    ],
  };
};

interface IAnnualNoiCashOnCashYear {
  year: number;
  noi: number;
  netOperatingProjetCashFlow: number;
  projectCashOnCash: number;
  lpInvestorCashOnCash: number;
}

export interface IAnnualNoiCashOnCashSummary extends IInvestmentSummaryTable {
  years: IAnnualNoiCashOnCashYear[];
}

const getAnnualNoiCashOnCashSummary = (
  property: IProperty,
  cashFlow: NetCashflowWaterfallDistribution
): IAnnualNoiCashOnCashSummary => {
  const { monthlyProforma } = cashFlow;
  const {
    investmentTimeline: { holdPeriodYears },
  } = property;
  const { leveredCashFlow, netLpInvestorCashFlow, totalEquity, lpInvestorCashOnCashFlow } = cashFlow;
  const years: IAnnualNoiCashOnCashYear[] = [];

  if (monthlyProforma.length > 0) {
    let totalNoi = 0;
    let totalNetOperatingProjetCashFlow = 0;
    if (monthlyProforma.length > 0) {
      const proformaYears = new Set(
        monthlyProforma.filter((m) => m.year > 0).map((m) => m.year)
      );
      proformaYears.forEach((proformaYear) => {
        const noi = cashFlow.getNoiByYear(proformaYear);
        const netOperatingProjetCashFlow =
          cashFlow.getNetOperatingProjetCashFlow(proformaYear);
        const projectCashOnCash =
          100 *
          (netOperatingProjetCashFlow /
            leveredCashFlow.totalEquityInvested);
        const lpInvestorCashOnCash =
          100 *
          //@ts-ignore
          (lpInvestorCashOnCashFlow[`year${proformaYear}`] /
            netLpInvestorCashFlow.totalEquityInvested);
        years.push({
          year: proformaYear,
          noi,
          netOperatingProjetCashFlow: getCleanNumber(
            Math.max(0, netOperatingProjetCashFlow)
          ),
          projectCashOnCash: getCleanNumber(projectCashOnCash),
          lpInvestorCashOnCash: getCleanNumber(lpInvestorCashOnCash),
        });

        totalNoi += noi;
        totalNetOperatingProjetCashFlow += Math.max(
          0,
          netOperatingProjetCashFlow
        );
      });
    }

    years.push({
      year: 0,
      noi: totalNoi,
      netOperatingProjetCashFlow: getCleanNumber(
        totalNetOperatingProjetCashFlow
      ),
      projectCashOnCash: getCleanNumber(
        (totalNetOperatingProjetCashFlow /
          totalEquity /
          holdPeriodYears) *
          100
      ),
      lpInvestorCashOnCash: getCleanNumber(
        (lpInvestorCashOnCashFlow.total /
          netLpInvestorCashFlow.totalEquityInvested /
          holdPeriodYears) *
          100
      ),
    });
  }

  const summary: IAnnualNoiCashOnCashSummary = {
    years,
    exportData: [
      {
        name: "Annual NOI & Cash-on-Cash Summary",
        values: [null, null, null, null, null],
      },
      {
        name: "Year",
        values: [
          "NOI",
          "Net Operating Project Cash Flow	",
          "Cash-on-Cash",
          null,
        ],
      },
      {
        values: [null, null, "Project", "LP Investor"],
      },
    ],
  };

  years.forEach((year) => {
    summary.exportData.push({
      name: year.year.toString(),
      values: [
        year.noi,
        year.netOperatingProjetCashFlow,
        year.projectCashOnCash,
        year.lpInvestorCashOnCash,
      ],
    });
  });

  return summary;
};

export interface IReturns extends IInvestmentSummaryTable {
  investorIRR: number;
  investorEquityMultiple: string;
  investorEquityInvested: number;
  totalInvestorDistributions: number;
  investorProfit: number;
  lPAvgAnnualCashOnCashReturnWithoutSale: number;
  totalAverageAnnualReturnWithSale: number;
}

const getNetReturnsToInvestors = (
  property: IProperty,
  cashFlow: NetCashflowWaterfallDistribution
): IReturns => {
  const { monthlyProforma } = cashFlow;
  const {
    investmentTimeline: { holdPeriodYears },
  } = property;
  const { lpInvestorCashOnCashFlow, netLpInvestorCashFlow } = cashFlow;

  let averageAnnualCashOnCash = 0;
  let averageAnnualReturn = 0;

  if (monthlyProforma.length > 0) {
    averageAnnualCashOnCash =
      (lpInvestorCashOnCashFlow.total /
        netLpInvestorCashFlow.totalEquityInvested /
        holdPeriodYears) *
      100;
    averageAnnualReturn =
      ((netLpInvestorCashFlow.equityMultiple - 1) * 1.0) /
      holdPeriodYears;
  }

  return {
    investorIRR: netLpInvestorCashFlow.irr,
    investorEquityMultiple:
      getCleanNumber(netLpInvestorCashFlow.equityMultiple).toFixed(2) +
      "x",
    investorEquityInvested: netLpInvestorCashFlow.totalEquityInvested,
    totalInvestorDistributions:
      netLpInvestorCashFlow.totalDistributions,
    investorProfit: netLpInvestorCashFlow.profit,
    lPAvgAnnualCashOnCashReturnWithoutSale: averageAnnualCashOnCash,
    totalAverageAnnualReturnWithSale: averageAnnualReturn * 100,
    exportData: [
      { name: "Net Returns to Investors", values: [null] },
      {
        name: "Investor IRR",
        values: [getCleanNumber(netLpInvestorCashFlow.irr)],
      },
      {
        name: "Investor Equity Multiple	",
        values: [
          getCleanNumber(netLpInvestorCashFlow.equityMultiple).toFixed(
            2
          ) + "x",
        ],
      },
      {
        name: "Investor Equity Invested	",
        values: [
          getCleanNumber(netLpInvestorCashFlow.totalEquityInvested),
        ],
      },
      {
        name: "Total Investor Distributions	",
        values: [
          getCleanNumber(netLpInvestorCashFlow.totalDistributions),
        ],
      },
      {
        name: "Investor Profit",
        values: [getCleanNumber(netLpInvestorCashFlow.profit)],
      },
      {
        name: "LP Avg. Annual Cash-on-Cash Return (w/out sale)	",
        values: [getCleanNumber(averageAnnualCashOnCash)],
      },
      {
        name: "Total Average Annual Return (w/sale)	",
        values: [getCleanNumber(averageAnnualReturn * 100)],
      },
    ],
  };
};

const getHypotheticalInvestment = (
  property: IProperty,
  cashFlow: NetCashflowWaterfallDistribution
): IReturns => {
  const { monthlyProforma } = cashFlow;
  const {
    investmentTimeline: { holdPeriodYears },
  } = property;
  const { netLpInvestorCashFlow, hypotheticalCashFlow, } = cashFlow;

  let lpAvgAnnualCashOnCashReturn = 0;
  let annualAverageReturn = 0;

  if (monthlyProforma.length > 0) {
    const years = cashFlow.getHypotheticalCashFlowYears();
    const netOperatingProjectCashFlow = years
      .map((y) => y.lpInvestorCashOnCash)
      .reduce((prev, curr) => prev + curr);
    lpAvgAnnualCashOnCashReturn =
      (netOperatingProjectCashFlow / 100000 / holdPeriodYears) * 100;
    annualAverageReturn =
      (100 * ((netLpInvestorCashFlow.equityMultiple - 1) * 1.0)) /
      holdPeriodYears;
  }

  return {
    investorIRR: hypotheticalCashFlow.irr,
    investorEquityMultiple:
      getCleanNumber(hypotheticalCashFlow.equityMultiple).toFixed(2) +
      "x",
    investorEquityInvested: hypotheticalCashFlow.totalEquityInvested,
    totalInvestorDistributions:
      hypotheticalCashFlow.totalDistributions,
    investorProfit: hypotheticalCashFlow.profit,
    lPAvgAnnualCashOnCashReturnWithoutSale: lpAvgAnnualCashOnCashReturn,
    totalAverageAnnualReturnWithSale: annualAverageReturn * 100,
    exportData: [
      { name: "Net Returns to Investors", values: [null] },
      {
        name: "Investor IRR",
        values: [getCleanNumber(hypotheticalCashFlow.irr)],
      },
      {
        name: "Investor Equity Multiple	",
        values: [
          getCleanNumber(hypotheticalCashFlow.equityMultiple).toFixed(
            2
          ) + "x",
        ],
      },
      {
        name: "Investor Equity Invested	",
        values: [
          getCleanNumber(hypotheticalCashFlow.totalEquityInvested),
        ],
      },
      {
        name: "Total Investor Distributions	",
        values: [
          getCleanNumber(hypotheticalCashFlow.totalDistributions),
        ],
      },
      {
        name: "Investor Profit",
        values: [getCleanNumber(hypotheticalCashFlow.profit)],
      },
      {
        name: "LP Avg. Annual Cash-on-Cash Return (w/out sale)	",
        values: [getCleanNumber(lpAvgAnnualCashOnCashReturn)],
      },
      {
        name: "Total Average Annual Return (w/sale)	",
        values: [getCleanNumber(annualAverageReturn * 100)],
      },
    ],
  };
};

interface IHypotheticalCashFlowYear {
  year: number;
  lpInvestorCashOnCash: number;
  lpInvestorCashOnCashPercentage: number;
  capitalEventCashFlow: number;
  capitalEventReturn: number;
}

export interface IHypotheticalInvestmentCashFlow
  extends IInvestmentSummaryTable {
  years: IHypotheticalCashFlowYear[];
  netOperatingProjectCashFlow: number;
  cashOnCashReturn: number;
  capitalEventCashFlow: number;
  capitalEventReturn: number;
}

const getHypotheticalInvestmentCashFlow = (
  property: IProperty,
  cashFlow: NetCashflowWaterfallDistribution
): IHypotheticalInvestmentCashFlow => {
  const {
    investmentTimeline: { holdPeriodYears },
  } = property;
  const { monthlyProforma } = cashFlow;

  let data: IHypotheticalInvestmentCashFlow = {
    years: [],
    netOperatingProjectCashFlow: 0,
    cashOnCashReturn: 0,
    capitalEventCashFlow: 0,
    capitalEventReturn: 0,
    exportData: [],
  };

  if (monthlyProforma.length > 0) {
    data.years = cashFlow.getHypotheticalCashFlowYears().map((year) => ({
      year: year.year,
      lpInvestorCashOnCash: getCleanNumber(year.lpInvestorCashOnCash),
      lpInvestorCashOnCashPercentage: getCleanNumber(
        year.lpInvestorCashOnCashPercentage
      ),
      capitalEventCashFlow: getCleanNumber(year.capitalEventCashFlow),
      capitalEventReturn: getCleanNumber(year.capitalEventReturn),
    }));
    const netOperatingProjectCashFlow = data.years
      .map((y) => y.lpInvestorCashOnCash)
      .reduce((prev, curr) => prev + curr);

    data.netOperatingProjectCashFlow = netOperatingProjectCashFlow;
    data.cashOnCashReturn =
      (netOperatingProjectCashFlow / 100000 / holdPeriodYears) * 100;
    data.capitalEventCashFlow = data.years
      .map((y) => y.capitalEventCashFlow)
      .reduce((prev, curr) => prev + curr);
    data.capitalEventReturn =
      (data.years
        .map((y) => y.capitalEventReturn)
        .reduce((prev, curr) => prev + curr) -
        100) /
      holdPeriodYears;
    data.years.push({
      year: 0,
      lpInvestorCashOnCash: 0,
      capitalEventCashFlow: 0,
      lpInvestorCashOnCashPercentage: 0,
      capitalEventReturn: 0,
    });

    data.exportData = [
      {
        name: "Hypothetical $100,000 Investment Cash Flow",
        values: [null, null, null, null],
      },
      {
        name: "Year",
        values: [
          "Net Operating Project Cash Flow",
          "Cash on Cash Return",
          "Capital Event Cash Flow",
          "Capital Event Return %",
        ],
      },
    ];

    data.years.forEach((year) => {
      data.exportData.push({
        name: year.year.toString(),
        values: [
          year.lpInvestorCashOnCash,
          year.lpInvestorCashOnCashPercentage,
          year.capitalEventCashFlow,
          year.capitalEventReturn,
        ],
      });
    });
  }

  return data;
};

export interface IHypotheticalInvestmentReturnVsHoldPeriod
  extends IInvestmentSummaryTable {
  years: ICashFlow[];
}

const getHypotheticalInvestmentReturnVsHoldPeriod = (
  property: IProperty,
  loan: ILoan,
  cashFlow: NetCashflowWaterfallDistribution
): IHypotheticalInvestmentReturnVsHoldPeriod => {
  const years = [
    calculateExitDisposition(property, loan, cashFlow!, 36),
    calculateExitDisposition(property, loan, cashFlow!, 48),
    calculateExitDisposition(property, loan, cashFlow!, 60),
    calculateExitDisposition(property, loan, cashFlow!, 72),
    calculateExitDisposition(property, loan, cashFlow!, 84),
    calculateExitDisposition(property, loan, cashFlow!, 96),
    calculateExitDisposition(property, loan, cashFlow!, 108),
    calculateExitDisposition(property, loan, cashFlow!, 120),
  ];

  return {
    years: years.map((year) => ({
      ...year,
      equityMultiple: getCleanNumber(year.equityMultiple),
    })),
    exportData: [
      {
        name: "Hypothetical $100,000 Investment - Return vs. Hold Period",
        values: [null, null, null, null, null, null, null, null],
      },
      {
        values: [
          "Year 3",
          "Year 4",
          "Year 5",
          "Year 6",
          "Year 7",
          "Year 8",
          "Year 9",
          "Year 10",
        ],
      },
      {
        values: [
          "36 Months",
          "48 Months",
          "60 Months",
          "72 Months",
          "84 Months",
          "96 Months",
          "108 Months",
          "120 Months",
        ],
      },
      {
        name: "Investor IRR",
        values: [
          years[0].irr,
          years[1].irr,
          years[2].irr,
          years[3].irr,
          years[4].irr,
          years[5].irr,
          years[6].irr,
          years[7].irr,
        ],
      },
      {
        name: "Investor Equity Multiple",
        values: [
          getCleanNumber(years[0].equityMultiple).toFixed(2) + "x",
          getCleanNumber(years[1].equityMultiple).toFixed(2) + "x",
          getCleanNumber(years[2].equityMultiple).toFixed(2) + "x",
          getCleanNumber(years[3].equityMultiple).toFixed(2) + "x",
          getCleanNumber(years[4].equityMultiple).toFixed(2) + "x",
          getCleanNumber(years[5].equityMultiple).toFixed(2) + "x",
          getCleanNumber(years[6].equityMultiple).toFixed(2) + "x",
          getCleanNumber(years[7].equityMultiple).toFixed(2) + "x",
        ],
      },
      {
        name: "Investor Profit",
        values: [
          getCleanNumber(years[0].profit),
          getCleanNumber(years[1].profit),
          getCleanNumber(years[2].profit),
          getCleanNumber(years[3].profit),
          getCleanNumber(years[4].profit),
          getCleanNumber(years[5].profit),
          getCleanNumber(years[6].profit),
          getCleanNumber(years[7].profit),
        ],
      },
    ],
  };
};

export interface IInvestmentOverview extends IInvestmentSummaryTable {
  overview: string;
  marketInformationAndCurrentStatus: string;
  propertyId: string;
  exportData2: IExportDataRow[];
}

const getInvestmentOverview = (property: IProperty): IInvestmentOverview => {
  const { _id, investmentOverview, marketInformationAndCurrentStatus } =
    property;

  return {
    propertyId: _id!,
    overview: investmentOverview,
    marketInformationAndCurrentStatus,
    exportData: [
      { name: "Investment Overview", values: [] },
      { name: investmentOverview, values: [] },
    ],
    exportData2: [
      { name: "Market Information & Current Status", values: [] },
      { name: marketInformationAndCurrentStatus, values: [] },
    ],
  };
};

interface IInvestmentSummary {
  propertySummary: IPropertySummary;
  ownershipStructure: IOwnershipStructure;
  projectLevelReturns: IProjectLevelReturns;
  annualNoiCashOnCashSummary: IAnnualNoiCashOnCashSummary;
  netReturnsToInvestors: IReturns;
  hypotheticalInvestment: IReturns;
  hypotheticalInvestmentCashFlow: IHypotheticalInvestmentCashFlow;
  hypotheticalInvestmentReturnVsHoldPeriod: IHypotheticalInvestmentReturnVsHoldPeriod;
  investmentOverview: IInvestmentOverview;
}

const getInvestmentSummary = (
  property: IProperty,
  loan: ILoan,
  cashFlow: NetCashflowWaterfallDistribution
): IInvestmentSummary => {
  return {
    propertySummary: getPropertySummary(property, loan, cashFlow),
    ownershipStructure: getOwnershipStructure(property, loan, cashFlow),
    projectLevelReturns: getProjectLevelReturns(property, cashFlow),
    annualNoiCashOnCashSummary: getAnnualNoiCashOnCashSummary(
      property,
      cashFlow
    ),
    netReturnsToInvestors: getNetReturnsToInvestors(property, cashFlow),
    hypotheticalInvestment: getHypotheticalInvestment(property, cashFlow),
    hypotheticalInvestmentCashFlow: getHypotheticalInvestmentCashFlow(
      property,
      cashFlow
    ),
    hypotheticalInvestmentReturnVsHoldPeriod:
      getHypotheticalInvestmentReturnVsHoldPeriod(property, loan, cashFlow),
    investmentOverview: getInvestmentOverview(property),
  };
};

const getCleanNumber = (value: number) => {
  if (isNaN(value) || !isFinite(value)) {
    return 0;
  }

  return value;
};

interface ITransformedExportDataRow {
  column1?: any;
  column2?: any;
  column3?: any;
  column4?: any;
  column5?: any;
  column6?: any;
  column7?: any;
  column8?: any;
  column9?: any;
  column10?: any;
}

const transformExportData = (exportData: IExportDataRow[]) => {
  const transformedExportData: ITransformedExportDataRow[] = [];
  exportData.forEach((row) => {
    let transformedRow: ITransformedExportDataRow = {};
    transformedRow.column1 = row.name;
    row.values.forEach((value, index) => {
      //@ts-ignore
      transformedRow[`column${index + 2}`] = value;
    });
    transformedExportData.push(transformedRow);
  });

  return transformedExportData;
};

const downloadSummary = (
  propertyName: string,
  investmentSummary: IInvestmentSummary
) => {
  const {
    propertySummary,
    ownershipStructure,
    projectLevelReturns,
    annualNoiCashOnCashSummary,
    netReturnsToInvestors,
    hypotheticalInvestment,
    hypotheticalInvestmentCashFlow,
    hypotheticalInvestmentReturnVsHoldPeriod,
    investmentOverview,
  } = investmentSummary;

  const sheets = [
    {
      name: "Property Summary",
      data: utils.json_to_sheet(
        transformExportData(propertySummary.exportData),
        {
          skipHeader: true,
        }
      ),
    },
    {
      name: "Ownership Structure",
      data: utils.json_to_sheet(
        transformExportData(ownershipStructure.exportData),
        {
          skipHeader: true,
        }
      ),
    },
    {
      name: "Project Level Returns",
      data: utils.json_to_sheet(
        transformExportData(projectLevelReturns.exportData),
        {
          skipHeader: true,
        }
      ),
    },
    {
      name: "Annual NOI & C.O.C. Summary",
      data: utils.json_to_sheet(
        transformExportData(annualNoiCashOnCashSummary.exportData),
        {
          skipHeader: true,
        }
      ),
    },
    {
      name: "Net Returns to Investors",
      data: utils.json_to_sheet(
        transformExportData(netReturnsToInvestors.exportData),
        {
          skipHeader: true,
        }
      ),
    },
    {
      name: "Hypothetical $100K Investment",
      data: utils.json_to_sheet(
        transformExportData(hypotheticalInvestment.exportData),
        {
          skipHeader: true,
        }
      ),
    },
    {
      name: "Hypothetical Cash Flow",
      data: utils.json_to_sheet(
        transformExportData(hypotheticalInvestmentCashFlow.exportData),
        {
          skipHeader: true,
        }
      ),
    },
    {
      name: "Hypothetical Return Vs Hold Per",
      data: utils.json_to_sheet(
        transformExportData(
          hypotheticalInvestmentReturnVsHoldPeriod.exportData
        ),
        {
          skipHeader: true,
        }
      ),
    },
    {
      name: "Investment Overview",
      data: utils.json_to_sheet(
        transformExportData(investmentOverview.exportData),
        {
          skipHeader: true,
        }
      ),
    },
    {
      name: "Market Information & Status",
      data: utils.json_to_sheet(
        transformExportData(investmentOverview.exportData2),
        {
          skipHeader: true,
        }
      ),
    },
  ];

  const wb = utils.book_new();
  sheets.forEach((sheet) => {
    utils.book_append_sheet(wb, sheet.data, sheet.name);
  });
  writeFile(
    wb,
    `${
      (propertyName.replaceAll(" ", "_") || "property").toLowerCase() +
      "_investment_summary"
    }.xlsx`
  );
};

export { getInvestmentSummary, downloadSummary };
